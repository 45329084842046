<template>
    <section class="position-relative z-index-1 padding-y-xl">
      <div class="container max-width-adaptive-lg">
        <div class="grid gap-lg items-center">
          

          <div class="col-7@md">
            <figure class="td-card js-td-card">
              <img class="block width-100% shadow-md radius-lg bg-accent" src="@/assets/pigs-in-motion/Gross.gif" alt="Image description">
            </figure>
          </div>
          <div class="col-5@md">
            <div class="text-component">
              <h1>Rolling in the Mud</h1>
              <div class="text-left margin-top-sm">
                The FPP Collection is subcategorized by rarity into four, uniquely cute tiers. The breakdown is below:
                <div class="rarity-container"> 
                    <div v-for="value, key in rarities" :key="key" :style="'background-color: ' + value.color + ( key =='The Fabled Ones' ? ';color: white;' : ';') " class="rarity flex justify-between shadow-sm padding-xxs radius-md margin-top-xs"> <div>{{key}}</div><div>{{value.name}}</div></div>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>


<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const rarities = {
            "Upiguitous": { name: "60%", color: 'var(--color-primary-lighter)'},
            "Prolific Piggies": { name: "30%", color: 'var(--color-primary-light)'},
            "Seldom Swine": { name: "9.75%", color: 'var(--color-primary)'},
            "The Fabled Ones": { name: "0.25%", color: 'var(--color-primary-dark)'}
        }
        
        return {rarities}
    }
})
</script>
<style lang="scss">
  .rarity {
      width: 100%;
      //max-width: 30rem;
  }
</style>
