<template>
    <div>
        <SimpleHeader>
            <WilbursInfo></WilbursInfo>
        </SimpleHeader>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import SimpleHeader from '@/components/simple-header/SimpleHeader.vue'
import WilbursInfo from '@/components/wilburs/WilbursInfo.vue'
export default defineComponent({
    setup() {
        
    },
    components:{
        SimpleHeader,
        WilbursInfo
    }
})
</script>

