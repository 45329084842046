<template>
    <section class="position-relative z-index-1 padding-y-xl">
      <div class="container max-width-adaptive-lg">
        <div class="grid gap-lg items-center">
          <div class="col-5@md">
            <div class="text-component">
              <h1>Who are the Pigs?</h1>
              <div class="text-left">
                Pouring love and labor into the art of our project was a priority, we think our JPIGs turned out pretty cute and we hope you do too! 
              </div>
              <div class="text-left">
                Here is what you need to know:
              </div>
              <div class="text-left margin-top-sm">
                There are 158 unique traits spread across 10 trait categories. This means that there are over half a Trillion unique combinations of JPIGs possible! WOW!
              </div>
              <div class="text-left margin-top-sm">
                Unfortunately, we can’t possibly create that many JPIGs, so there will only ever be 7887. They won’t be available forever and deserve a special home. Grab yours now! Join the parade!
              </div>
            </div>
          </div>

          <div class="col-7@md" style="margin: auto; height: 500px;" >

            <div id="slided-show" class="tilted-slideshow position-relative padding-top-md width-80% margin-x-auto width-70%@sm padding-top-0@md js-tilted-slideshow" style="height: 100%;">

              <ul id="slided-show" class="tilted-slideshow__list position-relative perspective-xs js-tilted-slideshow__list">
                <li v-for="pig, index in SlideShowPigs" :key="index" :class="slideShowClass(index)">
                  <img :src="pig" alt="Image Description1">
                </li>

              </ul>

              
            </div>
          </div>
        </div>
      </div>
    </section>
</template>


<script>
import { defineComponent } from 'vue'
import SlideShowPigs from './SlideShowPigs'

export default defineComponent({
    setup() {
        const slideShowClass = (index) => {
          const classPrefix = 'tilted-slideshow__item js-tilted-slideshow__item tilted-slideshow__item'
          if (index == 0) 
            return classPrefix + '--top'
          if (index == 1) 
            return classPrefix + '--middle'
          return classPrefix + '--bottom'
        }
        return { SlideShowPigs, slideShowClass }
    },
    mounted() { // 👈 load the JS code once the component is mounted
      let ValueProposition = document.createElement('script');
      ValueProposition.setAttribute('src', './js/components/_1_tilted-img-slideshow.js'); // 👈 make sure to use the correct path
      ValueProposition.setAttribute('id', '_1_tilted-img-slideshow-js');

      document.body.appendChild(ValueProposition);

    },
    unmounted() { // remove the JS code once the component has been destroyed
      document.getElementById('_1_tilted-img-slideshow-js').remove()
    }
})
</script>
