

const easeInOutQuad = function (t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
  };

  const scrollToPoint = function(final, duration, cb, scrollEl) {
    var element = scrollEl || window;
    var start = element.scrollTop || document.documentElement.scrollTop,
      currentTime = null;

    if(!scrollEl) start = window.scrollY || document.documentElement.scrollTop;

    var animateScroll = function(timestamp){
      if (!currentTime) currentTime = timestamp;        
      var progress = timestamp - currentTime;
      if(progress > duration) progress = duration;
      var val = easeInOutQuad(progress, start, final-start, duration);
      element.scrollTo(0, val);
      if(progress < duration) {
        window.requestAnimationFrame(animateScroll);
      } else {
        cb && cb();
      }
    };

    window.requestAnimationFrame(animateScroll);
  };

  const scrollTo = (target) => {
    var windowScrollTop = window.scrollY || document.documentElement.scrollTop
    scrollToPoint(target.getBoundingClientRect().top + windowScrollTop, 300, () => {}, null)
  }


  export default {scrollTo}