export default [
    {
        title: "Codex on the Flight of Pigs",
        description: [
             { content: "Assemble our Team", done: true},
             { content: "Craft Vision for Project Mission", done: true},
             { content: "Curate darn cute JPIGs", done: true},
             { content: "Create smartcontract for fair minting and distribution of 1/1 JPIGs", done: true},
             { content: "Create Website, Whitepaper, Social Media Platforms", done: true},
             { content: "Begin discussions with industry-leading architects for metaversal experiences", done: true},
        ]
    },
    {
        title: "A Kitty, a Hawk, and 7887 Pigs.",
        description: [
             { content: "Commence minting", done: false},
             { content: "Continued marketing, giveaways through minting process, charitable donations, collaboration with and support of other Web3-focused projects", done: false},
             { content: "Flappy Pig (JPIG-exclusive game with Whitelist access and giveaway prizes)", done: false},
             { content: "Announce the Compigtition", done: false},
             { content: "Skydiving Marketing Promotion", done: false}
        ]
    },
    {
        title: "The Spirit of Pig Sooie",
        description: [
             { content: "Swine Swag Merch Store for JPIG holders", done: false},
             { content: "Winner of The Compigtition announced", done: false},
             { content: "Barnyard Bash (hosted physical event)", done: false},
             { content: "Community reveal of Web3 event space (Wilbur’s)", done: false}
        ]
    },
    {
        title: "Air Pork One",
        description: [
             { content: "Sty in the Sky (Soft opening of Wilbur’s for JPIG holders)", done: false},
             { content: "3D Metaverse avatars ", done: false}
        ]
    },
    {
        title: "OonMay",
        description: [
            { content: "Subsequent Launch", done: false},
            { content: "Community updates and pursued pivots", done: false},
            { content: "NFT Staking Availability", done: false}
            
        ]
    }
]