<template>
    <section class="position-relative z-index-1 padding-y-xl">
      <div class="container max-width-adaptive-lg">
        <div class="grid gap-lg items-center" style="width:100%;">
          <h1 style="position:relative;">Huh?
            <img src="@/assets/clouds/SVG/Cloud2.svg" style="position:absolute; height: 7rem; top: -2.5rem; left: 50%; z-index:-1; transform: translateX(-50%);">
          </h1>
          <FaqQuestion v-for="prompt, answer in questions" :prompt="answer" :answer="prompt" :key="prompt"></FaqQuestion>
        </div>
      </div>
    </section>
</template>


<script>
import { defineComponent, ref } from 'vue'
import FaqQuestion from './FaqQuestion.vue'
import questions from './Questions'
export default defineComponent({
    setup() {
        var expand = ref(false)
        const expandItem = () => {
            expand.value = !expand.value
        }
        return { expand, expandItem, questions}
    },
    components: {
      FaqQuestion
    }
})
</script>
