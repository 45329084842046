<template>
    <div>
        <section class="position-relative z-index-2 bg width-100% truncate">
          <div class="container min-height-100vh">
            <div class="logo-container">
              <div class="logo">
                <img src="@/assets/logo_pink.png">
                <h2 class="color-accent margin-left-sm" style="text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;">Mint Coming Soon...</h2>
              </div>  
            </div>
             
            <img src="@/assets/clouds/cloud1.svg" class="cloud cloud-left" style="top: 2vh">
            <img src="@/assets/clouds/cloud1.svg" class="cloud cloud-right" style="top: 20vh; animation-duration: 10s">
            <img src="@/assets/clouds/cloud1.svg" class="cloud cloud-left" style="top: 40vh; animation-duration: 11s">
            <img src="@/assets/clouds/cloud1.svg" class="cloud cloud-right" style="top: 60vh; animation-duration: 9s">
            <img src="@/assets/clouds/cloud1.svg" class="cloud cloud-left" style="top: 80vh; animation-duration: 13s">
            <img src="@/assets/pigs-in-motion/Blue-small.gif" class="cloud cloud-left" style="top: 12vh; height: 10rem; z-index: 5; animation-duration: 10s;">
            <img src="@/assets/pigs-in-motion/Helicopter-small.gif" class="cloud cloud-right" style="top: 53vh;  height: 5rem; z-index:0; animation-duration: 17s;">
            <img src="@/assets/pigs-in-motion/Aviators-small.gif" class="cloud cloud-right" style="top: 73vh; animation-duration: 12s;">
            <img src="@/assets/pigs-in-motion/Carpet-small.gif" class="cloud cloud-right" style="top: 23vh; animation-duration: 11s;">
            <img src="@/assets/pigs-in-motion/Dragon-small.gif" class="cloud cloud-left" style="top: 5vh; height: 5rem; animation-duration: 15s;">
            <a class="btn btn--accent radius-50% down-btn" @click="scrollToFirstSection()">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490 490" xml:space="preserve" height="2rem" width="1rem">
                <g><path d="M52.8,311.3c-12.8-12.8-12.8-33.4,0-46.2c6.4-6.4,14.7-9.6,23.1-9.6s16.7,3.2,23.1,9.6l113.4,113.4V32.7c0-18,14.6-32.7,32.7-32.7c18,0,32.7,14.6,32.7,32.7v345.8L391,265.1c12.8-12.8,33.4-12.8,46.2,0c12.8,12.8,12.8,33.4,0,46.2L268.1,480.4c-6.1,6.1-14.4,9.6-23.1,9.6c-8.7,0-17-3.4-23.1-9.6L52.8,311.3z"/></g>
              </svg>
            </a>
          </div>
        </section>
    </div>
</template>
<script>

import { defineComponent } from 'vue'
import ScrollService from '@/services/ScrollService'
export default defineComponent({
    components: {
        
     },
     setup() {
       const scrollToFirstSection = () => {
         ScrollService.scrollTo(document.querySelector('#What'))
       }
       return {scrollToFirstSection}
     }
})
</script>
<style lang="scss" scoped>
.logo {
    animation: 1.5s grow cubic-bezier(.66,.12,.35,1.34);
    z-index: 1;
    will-change: transform;
}

.logo-container {
    position: absolute;
    width: 40vh;
    top: calc(50% - 5.25rem);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    will-change: transform;
}

@keyframes grow {
  from {
      transform: scale(0);
    }
  to {
      transform:  scale(1);
    }
}

.cloud {
  height: 20vh;
  position: absolute;
  opacity: 1;
  will-change: transform;

  
  &-left {
    left: 0;
    transform: translateX(-100%) rotateY(0);
    animation: slide-right 5s linear infinite  ;
  }
  &-right {
    right: 0;
    transform: translateX(100%) rotateY(180deg);
    animation: slide-left 5s linear infinite ;
  }
}



@keyframes slide-right {
  
  100% {
    transform: translateX(100vw) rotateY(0);
  }
}

@keyframes slide-left {
    
  100% {
    transform: translateX(-100vw) rotateY(180deg);
  }
}

.down-btn {
    position: absolute;
    bottom: 15%;
    left: 50%;
    
    transform: translateX(-50%);
    z-index: 2;
    &::after { /* pulse ring */
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: inherit;
      pointer-events: none;
      box-shadow: inset 0 0 1px 1px var(--color-accent);
      animation: poi-pulse 2s infinite;
      will-change: transform;
    }
}
@import '@/css/base/breakpoints.scss';

@media (map-get($map: $breakpoints, $key: 'md')) {
  .down-btn {
    height: 2rem;
    width: 2rem;
  }
}

.success {
    background-color: var(--color-success);
}

@keyframes poi-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
</style>
