<template>
    <div aria-controls="morph-modal-id" @click="$emit('modalOpen')" style="cursor: pointer">
        <img :class="directionStyles('pig')" :style="position(x, y)" :src="pig">
        <img :class="directionStyles('cloud')" :style="position(x, y + 15)" :src="cloud">
        <button :class="'btn btn--primary radius-50% modal-btn ' + (complete ? 'success' : '')" :style="buttonPosition(x, y)" @click="$emit('modalOpen')">
            <svg v-if="complete" class="icon" viewBox="0 0 16 16">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 8l4 4 8-8" />
            </svg>
            <svg v-else class="icon" viewBox="0 0 12 12" aria-hidden="true"><path d="M11,5H7V1A1,1,0,0,0,5,1V5H1A1,1,0,0,0,1,7H5v4a1,1,0,0,0,2,0V7h4a1,1,0,0,0,0-2Z"></path></svg>
        </button>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup(props) {
        const position = (x, y) => {
            let style = "top: " + y + "%;"
            if (x > 50) {
                style += " right: " + (100 - x) + "%;"
            } else {
                style += " left: " + x + "%;"
            }
            
            return style
        }

        const buttonPosition = (x, y) => {
            const buttonOffset = 17
            let style = "top: " + (y  + 10)+ "%;"
            if (x > 50) {
                style += " right: calc(" + (100 - x) + "% + " + buttonOffset + "rem);"
            } else {
                style += " left: calc(" + x + "% + " + buttonOffset + "rem);"
            }
            
            return style
        }
        const directionStyles = (name) => {
            return (name + ' ' + (props.x > 50 ? (name + '-right'): ''))
        }
        return {position, buttonPosition, directionStyles}
    },
    props: {
        x: Number,
        y: Number,
        pig: String,
        cloud: String,
        complete: Boolean
    }
})
</script>
<style lang="scss" scoped>
    .pig {
        position: absolute;
        height: 20rem;
        z-index: 1;
        animation: bounce 1s alternate-reverse infinite ease-in-out;
        will-change: transform;
        &-right {
            transform: rotateY(180deg);
            animation: bounce-right 1s alternate-reverse infinite ease-in-out;
        }
    }
    .cloud {
        position: absolute;
        height: 8rem;
        will-change: transform;
        &-right {
            transform: rotateY(180deg);
        }
    }
    @keyframes bounce-right {
        100% {
      transform: translateY(.5rem) rotateY(180deg);// scaleX(-1)
        }
    } 
    @keyframes bounce {
        100% {
            transform: translateY(.5rem);// scaleX(-1)
        }
    }
    .modal-btn {
        position: absolute;
        height: 2.5rem;
        width: 2.5rem;
        z-index: 2;
        &::after { /* pulse ring */
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: inherit;
          pointer-events: none;
          box-shadow: inset 0 0 1px 1px var(--color-accent);
          animation: poi-pulse 2s infinite;
          will-change: transform;
        }        
    }

    .success {
        background-color: var(--color-success);
    }

    @keyframes poi-pulse {
      0% {
        transform: scale(1);
        opacity: 1;
      }
    
      50% {
        opacity: 1;
      }
    
      100% {
        transform: scale(1.6);
        opacity: 0;
      }
    }
    
</style>
