<template>
    <section class="position-relative z-index-1 padding-y-xl">
      <div class="container max-width-adaptive-lg">
        <div class="grid gap-lg items-center">
          <div class="col-5@md">
            <div class="text-component">
              <h1>Meet The Pigs</h1>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, rem neque doloribus impedit explicabo.</p>
              <ul class="text-left margin-left-lg">
                  <li>30 Eyes</li>
                  <li>15 Hats</li>
                  <li>20 Body Types</li>
              </ul>
            </div>

            <div class="margin-top-sm">
              <div class="text-left margin-left-lg">
                <a href="#0" class="color-inherit">Learn more</a>
              </div>
            </div>
          </div>

          <div class="col-7@md">

            <div class="tilted-slideshow position-relative padding-top-md width-80% margin-x-auto width-70%@sm padding-top-0@md js-tilted-slideshow">
              <p class="sr-only">Tilted Image Slideshow</p>

              <ul class="tilted-slideshow__list position-relative perspective-xs js-tilted-slideshow__list">
                <li class="tilted-slideshow__item tilted-slideshow__item--top js-tilted-slideshow__item">
                  <img src="../../assets/pigs/aviators.png" alt="Image Description">
                </li>

                <li class="tilted-slideshow__item tilted-slideshow__item--middle js-tilted-slideshow__item">
                  <img src="../../assets/pigs/gross.png" alt="Image Description">
                </li>

                <li class="tilted-slideshow__item tilted-slideshow__item--bottom js-tilted-slideshow__item">
                  <img src="../../assets/pigs/novelpig.png" alt="Image Description">
                </li>
                <li class="tilted-slideshow__item tilted-slideshow__item--bottom js-tilted-slideshow__item">
                  <img src="../../assets/pigs/laidpig.png" alt="Image Description">
                </li>
              </ul>

              <span class="tilted-slideshow__touch-hint" aria-hidden="true">Tap to change image</span>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>


<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const pigImages = [
            'aviators',
            'laid pig',
            'novel pig',
        ]
        const getImageUrl = (pig) => {
            return ('./pigs/' + pig + '.png')
        }
        return {pigImages, getImageUrl}
    },
    mounted() { // 👈 load the JS code once the component is mounted
      let ValueProposition = document.createElement('script');
      ValueProposition.setAttribute('src', './js/components/_1_tilted-img-slideshow.js'); // 👈 make sure to use the correct path
      ValueProposition.setAttribute('id', '_1_tilted-img-slideshow-js');

      document.body.appendChild(ValueProposition);

    },
    unmounted() { // remove the JS code once the component has been destroyed
      document.getElementById('_1_tilted-img-slideshow-js').remove()
    }
})
</script>
