<template>
<div>
    <section class="position-relative js-cha-section" id="Home" data-theme="compigtion">
    <div class="cha-header-clip js-cha-header-clip z-index-header" >
      <header class="cha-header js-cha-header" >
        <div class="container max-width-lg padding-y-sm">
          <div class="flex justify-between items-center">
            
            <img src="assets/logo_pink.png" style="height: 3.5rem;">
            
            <nav class="cha-header__nav js-cha-header__nav">
              <ul class="flex items-center gap-md">
                <li><a href="#" class="cha-header__link" >Home</a></li>
                <li><a href="#Wilburs" class="cha-header__link">Wilbur's</a></li>
                <li><a href="https://fpp-whitepaper.s3.us-east-2.amazonaws.com/Whitepaper.pdf" target="_blank" class="cha-header__link">Whitepaper</a></li>
                <li><a href="#Lore" class="cha-header__link">Lore</a></li>
                <li>
                  <a class="coming-soon-v2__social-btn" href="https://twitter.com/fppnftproject" target="_blank">
                    <svg class="icon" viewBox="0 0 32 32">
                      <title>Follow us on Twitter</title>
                      <g>
                        <path d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3 c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5 c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"></path>
                      </g>
                    </svg>
                  </a> 
                  <a class="coming-soon-v2__social-btn" href="https://www.instagram.com/flyingpigparade.nft/" target="_blank">
                    <svg class="icon" width="256px" height="256px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                      <path d="M160,128a32,32,0,1,1-32-32A32.03667,32.03667,0,0,1,160,128Zm68-44v88a56.06353,56.06353,0,0,1-56,56H84a56.06353,56.06353,0,0,1-56-56V84A56.06353,56.06353,0,0,1,84,28h88A56.06353,56.06353,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48.05436,48.05436,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z"/>
                    </svg>
                  </a>
                  <a class="coming-soon-v2__social-btn" href="https://medium.com/@FlyingPigParade" target="_blank">
                    <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.285 7.269a.733.733 0 0 0-.24-.619l-1.77-2.133v-.32h5.498l4.25 9.32 3.736-9.32H21v.319l-1.515 1.451a.45.45 0 0 0-.168.425v10.666a.448.448 0 0 0 .168.425l1.479 1.451v.319h-7.436v-.319l1.529-1.487c.152-.15.152-.195.152-.424V8.401L10.95 19.218h-.575L5.417 8.401v7.249c-.041.305.06.612.275.833L7.684 18.9v.319H2.036V18.9l1.992-2.417a.971.971 0 0 0 .257-.833V7.269z"/></svg>
                  </a>
                </li>
                <li><a class="reset cha-header__btn" href="#Oinklist" style="text-decoration: none;">Whitelist</a></li>
              </ul>
            </nav>

            <button class="reset cha-header__trigger js-cha-header__trigger js-tab-focus" aria-label="Open navigation" aria-expanded="false" aria-controls="cha-header-mobile" @click="showModal = !showModal">
              <svg class="icon" viewBox="0 0 24 24"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" stroke="currentColor" fill="none" stroke-miterlimit="10"><line x1="1" y1="12" x2="23" y2="12"></line> <line stroke-miterlimit="10" x1="1" y1="5" x2="23" y2="5"></line> <line stroke-miterlimit="10" x1="1" y1="19" x2="23" y2="19"></line></g></svg>
            </button>
          </div>
        </div>
      </header>
    </div>
    
    <div class="container padding-top-xxl ">
      <slot/>
    </div>
  </section>
      <div class="cha-modal js-cha-modal z-index-header is-visible" id="cha-modal" v-if="showModal">
          <nav class="height-100% overflow-auto">
            <div class="bg padding-x-component padding-y-sm position-sticky z-index-1 top-0">
              <div class="flex justify-between items-center">
                <p class="color-contrast-medium">Menu</p>

                <button class="reset cha-modal__close js-cha-modal__close js-tab-focus" aria-label="Close navigation" @click="showModal = !showModal">
                  <svg class="icon" viewBox="0 0 24 24"><g stroke-linecap="square" stroke-linejoin="miter" fill="none" stroke-width="2" stroke="currentColor" stroke-miterlimit="10"><line x1="19" y1="5" x2="5" y2="19"></line><line x1="19" y1="19" x2="5" y2="5"></line></g></svg>
                </button>
              </div>
              </div>

            <div class="padding-x-component padding-y-lg">
              <ul class="flex flex-column gap-sm">
                <li><a href="#" class="cha-modal__link">Home</a></li>
                <li><a href="#Wilburs" class="cha-header__link">Wilbur's</a></li>
                <li><a href="#Whitepaper" class="cha-header__link">Whitepaper</a></li>
                <li><a href="#Lore" class="cha-header__link">Lore</a></li>
                <li>
                  <a class="coming-soon-v2__social-btn" href="https://twitter.com/fppnftproject" target="_blank">
                    <svg class="icon" viewBox="0 0 32 32">
                      <title>Follow us on Twitter</title>
                      <g>
                        <path d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3 c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5 c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"></path>
                      </g>
                    </svg>
                  </a> 
                  <a class="coming-soon-v2__social-btn" href="https://www.instagram.com/flyingpigparade.nft/" target="_blank">
                    <svg class="icon" width="256px" height="256px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                      <path d="M160,128a32,32,0,1,1-32-32A32.03667,32.03667,0,0,1,160,128Zm68-44v88a56.06353,56.06353,0,0,1-56,56H84a56.06353,56.06353,0,0,1-56-56V84A56.06353,56.06353,0,0,1,84,28h88A56.06353,56.06353,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48.05436,48.05436,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z"/>
                    </svg>
                  </a>
                  <a class="coming-soon-v2__social-btn" href="https://medium.com/@FlyingPigParade" target="_blank">
                    <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.285 7.269a.733.733 0 0 0-.24-.619l-1.77-2.133v-.32h5.498l4.25 9.32 3.736-9.32H21v.319l-1.515 1.451a.45.45 0 0 0-.168.425v10.666a.448.448 0 0 0 .168.425l1.479 1.451v.319h-7.436v-.319l1.529-1.487c.152-.15.152-.195.152-.424V8.401L10.95 19.218h-.575L5.417 8.401v7.249c-.041.305.06.612.275.833L7.684 18.9v.319H2.036V18.9l1.992-2.417a.971.971 0 0 0 .257-.833V7.269z"/></svg>
                  </a>
                </li>
                <li><a href="#Oinklist" class="btn btn--primary btn--lg width-100%"  style="text-decoration: none;">Whitelist</a></li>
              </ul>
            </div>
          </nav>
        </div>
    </div>
    
</template>
<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
    setup() {
      const showModal = ref(false)
      return { showModal }
    },
    props: {
      pageName: String
    }
})
</script>
