<template>
 <div id="app" data-theme="compigtion">
    <router-view />
    <!-- <chameleon-header></chameleon-header> -->
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
#app {
  font-family: Nunito, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@import "src/css/style.scss";
</style>
