<template>
  <div class="question-container" @click="expandItem()">
    <div style="padding: 0 3rem;">
      <h3>{{prompt}}</h3>
    </div>
    <svg :class="'expand  ' + (expand ? 'expand-open': '')" viewBox="0 0 20 20">
      <g class="icon__group" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
        <line x1="2" y1="10" x2="18" y2="10" />
        <line x1="10" y1="18" x2="10" y2="2" />
      </g>
    </svg>
    <transition name="fade">
      <div v-if="expand" style="padding: 1rem; overflow:hidden;">
        {{answer}}
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
export default defineComponent({
    setup() {
        var expand = ref(false)
        const expandItem = () => {
          expand.value = !expand.value
        }
        return { expand, expandItem}
    },
    props: {
      'prompt': String,
      'answer': String
    }
})
</script>

<style lang="scss" scoped>
  .question-container {
    position: relative;
    border: 1px solid black; 
    background-color: white; 
    transition: .1s all; 
    border-radius: 1rem; 
    padding: 1rem;
  }
  .expand {
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 2rem;
    transition: .2s all ease-in-out;
    stroke-width: 2px;
    &-open {
      transform: rotate(45deg);
      cursor: none;
    }
  }

  .fade-enter-active {
    transition: all .3s ease-out;
  }

  .fade-leave-active {
    transition: none;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>