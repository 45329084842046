<template>
    <div class="flex flex-center">
      <div class="container max-width-adaptive-lg bg bg-opacity-90% radius-lg padding-md reveal-fx reveal-fx--translate-up">
        <div class="grid gap-lg items-center@md">
          <!-- content -->
          <div class="col-6@md padding-left-md@lg">
            <img src="assets/logo_black.png" style="height: 3.5rem;">
    
            <div class="text-component margin-bottom-md">
              <div class="color-contrast-higher opacity-50% text-sm margin-bottom-xs">&mdash; Mint Coming soon</div>
              <h1>Join Our Oink List</h1>
              <p>The first 1000 JPIGs can be minted at the whitelist price! Check our socials out for more information.</p>
            </div>
    
            <div class="margin-bottom-md  text-left">

<!--             
              <form class="news-form  js-news-form">
                <label class="sr-only" for="news-input-name">Get notified when we launch</label>
    
                <div class="news-form__wrapper">
                  <input class="reset news-form__input js-news-form__input" type="text" placeholder="Whitelist Contract Address" name="news-input-name" id="news-input-name">
    
                  <button class="reset news-form__btn js-news-form__btn js-tab-focus" disabled>
                    <span>Sign Me Up</span>
    
                    <span>
                      <svg class="news-form__icon" viewBox="0 0 24 24">
                        <path class="news-form__icon-circle" d="M2,12a10,10,0,0,0,20,0" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                        <path class="news-form__icon-check" d="M2,12l6,6L22,4" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                        <line class="news-form__icon-excl-line" x1="12" y1="2" x2="12" y2="16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                        <circle class="news-form__icon-excl-dot" cx="12" cy="21.5" r="1.5" fill="currentColor" />
                      </svg>
                    </span>
                  </button>
    
                  <div role="alert" class="news-form__msg news-form__msg--error padding-xs radius-md text-sm">
                    <p><strong>Error:</strong> this is an error message</p>
                  </div>
    
                  <div role="alert" class="news-form__msg news-form__msg--success padding-xs radius-md text-sm">
                    <p><strong>Success!</strong> Check your inbox for a confirmation email.</p>
                  </div>
                </div>
              </form>
              <div class="padding-sm">
                <div class="g-recaptcha" data-sitekey="6LdoasAfAAAAANATigXtayiL-zUGfwFg7i5Eugul"></div>  
              </div>
               -->
            </div>
    
            <!-- socials -->
            <div class="text-center">
              <ul class="flex flex-wrap gap-xxxxs" style="justify-content: center">
                <li>
                  <a class="coming-soon-v2__social-btn" href="https://twitter.com/fppnftproject" target="_blank">
                    <svg class="icon" viewBox="0 0 32 32">
                      <title>Follow us on Twitter</title>
                      <g>
                        <path d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3 c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5 c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"></path>
                      </g>
                    </svg>
                  </a> 
                </li>
    
                <li>
                  <a class="coming-soon-v2__social-btn" href="https://www.instagram.com/flyingpigparade.nft/" target="_blank">
                    <svg class="icon" width="256px" height="256px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                      <path d="M160,128a32,32,0,1,1-32-32A32.03667,32.03667,0,0,1,160,128Zm68-44v88a56.06353,56.06353,0,0,1-56,56H84a56.06353,56.06353,0,0,1-56-56V84A56.06353,56.06353,0,0,1,84,28h88A56.06353,56.06353,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48.05436,48.05436,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z"/>
                    </svg>
                  </a>
                </li>
    
                <li>
                  <a class="coming-soon-v2__social-btn" href="https://medium.com/@FlyingPigParade" target="_blank">
                    <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.285 7.269a.733.733 0 0 0-.24-.619l-1.77-2.133v-.32h5.498l4.25 9.32 3.736-9.32H21v.319l-1.515 1.451a.45.45 0 0 0-.168.425v10.666a.448.448 0 0 0 .168.425l1.479 1.451v.319h-7.436v-.319l1.529-1.487c.152-.15.152-.195.152-.424V8.401L10.95 19.218h-.575L5.417 8.401v7.249c-.041.305.06.612.275.833L7.684 18.9v.319H2.036V18.9l1.992-2.417a.971.971 0 0 0 .257-.833V7.269z"/></svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
    
          <!-- 3D images -->
          <div class="col-6@md">
          
            <div class="td-cards td-cards--v2 flip-x">
              <div class="td-cards__inner">
                <!-- left card -->
                <div class="td-cards__card">
                  <img class="flip-x" src="../../assets/pigs/whatdidido.png" alt="Image description">
                </div>
    
                <!-- right card -->
                <div class="td-cards__card">
                  <img class="flip-x" src="../../assets/pigs/Hotairballoon.png" alt="Image description">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script >
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        
    },
    mounted() { // 👈 load the JS code once the component is mounted
      let revealEffects = document.createElement('script');
      revealEffects.setAttribute('src', './js/components/_1_reveal-effects.js'); // 👈 make sure to use the correct path
      revealEffects.setAttribute('id', '_1_reveal-effects-js');
      let newsLetter = document.createElement('script');
      newsLetter.setAttribute('src', './js/components/_1_newsletter-input.js'); // 👈 make sure to use the correct path
      newsLetter.setAttribute('id', '_1_newsletter-input-js');
      let captcha = document.createElement('script');
      captcha.setAttribute('src', "https://www.google.com/recaptcha/api.js"); // 👈 make sure to use the correct path
      captcha.setAttribute('id', 'captcha-js');

      document.body.appendChild(revealEffects);
      document.body.appendChild(newsLetter);
      document.body.appendChild(captcha)
      

    },
    unmounted() { // remove the JS code once the component has been destroyed
      document.getElementById('_1_reveal-effects-js').remove()
      document.getElementById('_1_newsletter-input-js').remove()
      document.getElementById('captcha-js').remove()
    }
})
</script>
