<template>
    <div>
        <SimpleHeader>
            <CallToAction></CallToAction>
        </SimpleHeader>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import SimpleHeader from '@/components/simple-header/SimpleHeader.vue'
import CallToAction from '@/components/call-to-action/CallToAction.vue'
export default defineComponent({
    setup() {
        
    },
    components:{
        SimpleHeader,
        CallToAction
    }
})
</script>
