<template src="./ChameleonHeader.html">
  
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import IntroCountdown from '../intro-countdown/IntroCountdown.vue'
import ValueProposition from '../value-proposition/ValueProposition.vue'
import CallToAction from '../call-to-action/CallToAction.vue'
import OurTeam from '../our-team/OurTeam.vue'
import AboutPigs from '../about-pigs/AboutPigs.vue'
import MainFooter from '../main-footer/MainFooter.vue'
import RoadMap from '../road-map/RoadMap.vue'
import FAQ from '../faq/FAQ.vue'
import AboutParade from '../about-parade/AboutParade.vue'
import PigRarity from '@/components/pig-rarity/PigRarity.vue'
import RevealSection from '@/components/reveal-section/RevealSection.vue'
import ScrollService from '@/services/ScrollService'
const componentCollection = [
  {id: 'What', c: IntroCountdown},
  { id: "Why", c : AboutParade },
  { id: "About", c : AboutPigs },
  { id: "How", c: PigRarity},
  { id: "Roadmap", c : RoadMap },
  { id: "Team", c : OurTeam },
  { id: "Oinklist", c : CallToAction },
  { id: "FAQ", c : FAQ },
  { id: "Footer", c : MainFooter }
]

export default defineComponent({
    components: {
        IntroCountdown,
        ValueProposition,
        CallToAction,
        OurTeam,
        AboutPigs,
        MainFooter,
        RoadMap,
        FAQ,
        AboutParade,
        PigRarity,
        RevealSection
    },
    mounted() { // 👈 load the JS code once the component is mounted
      let chameleonHeader = document.createElement('script');
      chameleonHeader.setAttribute('src', './js/components/_1_chameleonic-header.js'); // 👈 make sure to use the correct path
      chameleonHeader.setAttribute('id', '_1_chameleonic-header-js');
      document.body.appendChild(chameleonHeader);
    },
    unmounted() { // remove the JS code once the component has been destroyed
      document.getElementById('_1_chameleonic-header-js').remove()
    },
    beforeUnmount() { // remove the JS code once the component has been destroyed
      document.getElementsByClassName('cha-header-clip')[0].classList.add('js-cha-header-clip')
    },
    setup() {
      const theme = (index) => {
        if (index == 0 || index % 2 == 0) {
          return 'compigtion'
        }
        return 'compigtion-dark'
      }
      
      const scrollToHome = () => {
        let target = document.querySelector('#What')
        ScrollService.scrollTo(target)
      }

      return { componentCollection, theme, scrollToHome }
    }
})
</script>
<style lang="scss">
@import "@/css/base/breakpoints";
.social-links {
  display: block;
}
@include breakpoint(md) {
  .social-links {
    display: none;
  }
}

</style>
