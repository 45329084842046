<template>
    <section class="position-relative padding-y-xl">
        <div class="container max-width-adaptive-lg">
            <div class="map-container margin-bottom-xl">
                <h1>Evolution of Flight</h1>
                <CloudPig :x=0 :y=5 :pig="Gliding" :cloud="Cloud1" @modalOpen="updateModal(0)" :complete="true"></CloudPig>
                <CloudPig :x=100 :y=25 :pig="Wright" :cloud="Cloud1" @modalOpen="updateModal(1)"></CloudPig>
                <CloudPig :x=0 :y=45 :pig="Plane" :cloud="Cloud1" @modalOpen="updateModal(2)"></CloudPig>
                <CloudPig :x=100 :y=65 :pig="Jet" :cloud="Cloud1" @modalOpen="updateModal(3)"></CloudPig>
                <CloudPig :x=0 :y=85 :pig="Pegasus" :cloud="Cloud1" @modalOpen="updateModal(4)"></CloudPig>
            </div>
        </div>
        <div class="morph-img-modal modal modal--animate-fade flex flex-center bg-black bg-opacity-90% js-morph-img-modal js-modal z-index-3" id="morph-modal-id">
          <div class="morph-img-modal__content modal__content max-width-lg bg inner-glow shadow-md opacity-0 js-morph-img-modal__content radius-md position-relative" role="alertdialog" aria-label="Image modal window">
            
            <figure>
              <img class="morph-img-modal__img js-morph-img-modal__img" src="" alt="Image Preview">
            </figure>       

            <div class="morph-img-modal__info opacity-0 js-morph-img-modal__info padding-xl text-left">
              <h1 class="margin-bottom-xs">{{title}}</h1>
              <fieldset class="margin-bottom-md">
                <ul class="flex flex-column gap-xxxs">
                  <li v-for="item in description" :key="item">
                    <label class="todo__item">
                      <input class="todo__input" type="checkbox" :checked="item.done" disabled="true">
                      <span class="todo__checkbox" aria-hidden="true">
                        <svg class="icon" viewBox="0 0 16 16">
                          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 8l4 4 8-8" />
                        </svg>
                      </span>
                      <span class="todo__label">{{item.content}}</span>
                    </label>
                  </li>
                </ul>
              </fieldset>
            </div>
            <button class="btn radius-50% modal-btn js-close-morph-modal">
                <svg class="icon" viewBox="0 0 12 12" aria-hidden="true"><path d="M11,5H7V1A1,1,0,0,0,5,1V5H1A1,1,0,0,0,1,7H5v4a1,1,0,0,0,2,0V7h4a1,1,0,0,0,0-2Z"></path></svg>
            </button>
          </div>
        </div>

        <div class="morph-img-bg bg is-hidden js-morph-img-bg" aria-hidden="true"></div>
        <div class="morph-img-clone is-hidden js-morph-img-clone" aria-hidden="true"></div>       

        
    </section>
</template>

<script>
import { defineComponent, ref } from 'vue'
import CloudPig from './CloudPig.vue'
import Gliding from '@/assets/solo-pigs/Gliding.png'
import Plane from '@/assets/solo-pigs/Plane.png'
import Jet from '@/assets/solo-pigs/Jet.png'
import Wright from '@/assets/solo-pigs/Wright.png'
import Pegasus from '@/assets/solo-pigs/Pegasus.png'
import Wilburs from '@/assets/Wilburs.jpg'
import Cloud1 from '@/assets/clouds/cloud1.svg'
import RoadMapDescriptions from './RoadMapDescriptions'
export default defineComponent({
    setup() {
        const position = (x, y) => {
            return "left: " + x + "%; top: " + y + "%;"
        }
        
        var title = ref('')
        var description = ref('')
        const updateModal = (index) => {
          title.value = RoadMapDescriptions[index].title
          description.value = RoadMapDescriptions[index].description
        }
        return {position, updateModal, title, description, Cloud1, Gliding, Plane, Jet, Wright, Pegasus, Wilburs}
    },
    components: {
        CloudPig
    },
    mounted() { // 👈 load the JS code once the component is mounted
      let modalWindow = document.createElement('script');
      modalWindow.setAttribute('src', './js/components/_1_modal-window.js'); // 👈 make sure to use the correct path
      modalWindow.setAttribute('id', '_1_modal-window-js');
      document.body.appendChild(modalWindow);
      let morphingModal = document.createElement('script');
      morphingModal.setAttribute('src', './js/components/_2_morphing-image-modal.js'); // 👈 make sure to use the correct path
      morphingModal.setAttribute('id', '_2_morphing-image-modal-js');
      document.body.appendChild(morphingModal);
      

    },
    unmounted() { // remove the JS code once the component has been destroyed
      document.getElementById('_1_modal-window-js').remove()
      document.getElementById('_2_morphing-image-modal-js').remove()
    }
})
</script>
<style lang="scss" scoped>
  @import 'src/css/base/mixins.scss';
    .map-container {
        height: 100rem;
        width: 100%;
        max-width: 60rem;
        margin: auto;
        margin-bottom: var(--space-xxxl);
        position: relative;
    }
    .wilburs {
        position: absolute; 
        width: 80%; 
        top: 70%;
        animation: bounce 1s alternate-reverse infinite ease-in-out;
        transform: translateX(15%);
    }
    @keyframes bounce {
        100% {
            transform: translateY(.5rem) translateX(15%);// scaleX(-1)
        }
    }

    .modal-btn {
        position: absolute;
        height: 2.5rem;
        width: 2.5rem;
        z-index: 2;
        right: var(--space-xs);
        top: var(--space-xs);
        border: none !important;
        box-shadow: none !important;
        background-color:transparent !important;
        transform: rotateZ(45deg);
        &:active {
          transform: rotateZ(45deg);
        }
    }

  $todo-list-checkbox-size: 20px; /* checkbox size */
  $todo-list-checkbox-icon-size: 16px; /* checkbox icon size */
  $todo-list-checkbox-margin-right: var(--space-xxs); /* gap between custom input and content */

  .todo__item {
    display: inline-block;
    position: relative;
    padding-left: calc($todo-list-checkbox-size + $todo-list-checkbox-margin-right);
    user-select: none;
  }
  
  /* native checkbox input */
  .todo__input {
    position: absolute;
    padding: 0;
    margin: 0;
    opacity: 0;
    pointer-events: none;
  }
  
  /* custom checkbox */
  .todo__checkbox {
    display: inline-flex;
    background-color: alpha(var(--color-contrast-higher), 0.15);
    width: $todo-list-checkbox-size;
    height: $todo-list-checkbox-size;
    border-radius: 4px;
  
    position: relative;
    vertical-align: middle;
    top: -0.1em;
    margin-left: calc(-1 * ($todo-list-checkbox-size + $todo-list-checkbox-margin-right));
    margin-right: $todo-list-checkbox-margin-right;
  
    transition: background-color .2s, transform .2s, box-shadow .2s;
  
    .icon {
      display: block;
      margin: auto;
      color: var(--color-white); /* icon color */
      font-size: $todo-list-checkbox-icon-size;
    }
  
    .icon > * {
      transition: stroke-dashoffset .3s;
      stroke-dasharray: 18;
      stroke-dashoffset: 18;
    }
  }
  
  .todo__input:checked ~ .todo__checkbox {
    background-color: var(--color-primary);
  
    .icon > * {
      stroke-dasharray: 18;
      stroke-dashoffset: 0;
    }
  }

  .todo__label {
    background-repeat: no-repeat;
    will-change: background-size;
    background-size: 0% 100%;
    background-image: linear-gradient(transparent 50%, currentColor 50%, currentColor calc(50% + 1px), transparent calc(50% + 1px));
    padding-left: --todo-list-checkbox-margin-right;
    transition: background-size .3s var(--ease-in-out), color .3s;
  }
  
  .todo__input:checked ~ .todo__label {
    background-size: 100% 100%;
    color: var(--color-contrast-medium);
  }
</style>
