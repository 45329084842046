<template>
    <section class="padding-y-xxl">
      <div class="container max-width-adaptive-lg">
        <div class="grid gap-md items-center">
          <div class="col-6@md col-5@lg">
            <div class="text-component margin-bottom-md text-left">
              <h1>What is the Parade?</h1>
              <p>Flying Pig Parade is a cheerful and cute drove of 7887 randomly generated NFTs on the Ethereum Blockchain. FPP also includes the owners of the aptly named “JPIGs” who are committed to web3 community building through hosted events, participation in shared experiences, and good works.</p>
            </div>

            <!-- <div class="bg-light shadow-sm radius-md padding-sm">
              <div class="flex flex-wrap gap-sm justify-between items-center">
                <div>
                  <div class="countdown countdown--has-labels text-lg js-countdown" data-timer="604800" data-labels="D, H, M, S">
                    <p class="countdown__fallback">Mint Coming soon</p>
                  </div> 
                </div>
    
                <a href="#Oinklist" class="btn btn--primary">Whitelist</a>
              </div>
            </div> -->
          </div>

          <div class="col-6@md col-7@lg">
            <div class="td-cards td-cards--v5">
              <div class="td-cards__inner">
                <div class="td-cards__card">
                  <img src="../../assets/planes.jpg" alt="Image description">
                </div>

                <div class="td-cards__shadow" aria-hidden="true"></div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        
    },
    mounted() { // 👈 load the JS code once the component is mounted
      let countDownTimer = document.createElement('script');
      countDownTimer.setAttribute('src', './js/components/_1_countdown.js'); // 👈 make sure to use the correct path
      countDownTimer.setAttribute('id', '_1_countdown-js');

      //document.body.appendChild(countDownTimer);

    },
    unmounted() { // remove the JS code once the component has been destroyed
      //document.getElementById('_1_countdown-js').remove()
    }
})
</script>
<style lang="scss">
    /* -------------------------------- 

File#: _1_countdown
Title: CountDown
Descr: Widget to display a timer
Usage: codyhouse.co/license

-------------------------------- */

.countdown {
  display: flex;
  align-items: baseline;
}

.countdown__timer {
  display: inline-block;
}

.countdown__item { // time value + label - dynamically created using JS
  display: inline-block;

  &:nth-of-type(2)::after, &:nth-of-type(3)::after { // ':' separator -> H:M:S 
    content: ':';
  }
}

.countdown__value {
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}

// --has-labels
.countdown--has-labels {
  .countdown__item {
    margin-right: var(--space-xs);

    &:last-child {
      margin-right: 0;
    }
  }

  .countdown__item::after {
    content: '';
  }

  .countdown__label { // time label
    font-size: 0.45em;
  }
}

// --grid
.countdown--grid {
  .countdown__timer {
    --timer-gap: var(--space-xxxs); // grid items gap
    display: flex;
    margin-left: calc(-1 * var(--timer-gap));
  }

  .countdown__item {
    width: 1.6em;
    text-align: center;
    padding: var(--space-xxxxs) 0;
    margin-left: var(--timer-gap);
  }

  .countdown__item::after {
    content: '';
  }

  .countdown__label {
    display: block;
    text-transform: uppercase;
    font-size: 0.2em;
    letter-spacing: 0.1em;
    color: var(--color-contrast-medium);
  }
}

.js {
  .countdown__fallback { // if JS = enabled -> show description only to assistive technologies
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
  }
}

// if JS = disabled
html:not(.js) .countdown__info {
  display: none;
}
</style>

