<section class="position-relative js-cha-section scroll-smooth" id="Home" data-theme="compigtion">
    <div class="cha-header-clip js-cha-header-clip z-index-header" >
      <header class="cha-header js-cha-header" >
        <div class="container max-width-lg padding-y-sm">
          <div class="flex justify-between items-center">
            
            <img src="assets/logo_pink.png" style="height: 3.5rem; cursor: pointer;" @click="scrollToHome()">
            <div class="social-links margin-right-md margin-left-auto">
              <a class="coming-soon-v2__social-btn" href="https://twitter.com/fppnftproject" target="_blank">
                <svg class="icon" viewBox="0 0 32 32">
                  <title>Follow us on Twitter</title>
                  <g>
                    <path d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3 c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5 c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"></path>
                  </g>
                </svg>
              </a> 
              <a class="coming-soon-v2__social-btn" href="https://www.instagram.com/flyingpigparade.nft/" target="_blank">
                <svg class="icon" width="256px" height="256px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                  <path d="M160,128a32,32,0,1,1-32-32A32.03667,32.03667,0,0,1,160,128Zm68-44v88a56.06353,56.06353,0,0,1-56,56H84a56.06353,56.06353,0,0,1-56-56V84A56.06353,56.06353,0,0,1,84,28h88A56.06353,56.06353,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48.05436,48.05436,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z"/>
                </svg>
              </a>
              <a class="coming-soon-v2__social-btn" href="https://medium.com/@FlyingPigParade" target="_blank">
                <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.285 7.269a.733.733 0 0 0-.24-.619l-1.77-2.133v-.32h5.498l4.25 9.32 3.736-9.32H21v.319l-1.515 1.451a.45.45 0 0 0-.168.425v10.666a.448.448 0 0 0 .168.425l1.479 1.451v.319h-7.436v-.319l1.529-1.487c.152-.15.152-.195.152-.424V8.401L10.95 19.218h-.575L5.417 8.401v7.249c-.041.305.06.612.275.833L7.684 18.9v.319H2.036V18.9l1.992-2.417a.971.971 0 0 0 .257-.833V7.269z"/></svg>
              </a>
            </div>
            <nav class="cha-header__nav js-cha-header__nav">
              <ul class="flex items-center gap-md">
                <li><a href="#" class="cha-header__link">Home</a></li>
                <li><a href="#Wilburs" class="cha-header__link">Wilbur's</a></li>
                <li><a href="https://fpp-whitepaper.s3.us-east-2.amazonaws.com/Whitepaper.pdf" target="_blank" class="cha-header__link">Whitepaper</a></li>
                <li><a href="#Lore" class="cha-header__link">Lore</a></li>
                <li>
                  <a class="coming-soon-v2__social-btn" href="https://twitter.com/fppnftproject" target="_blank">
                    <svg class="icon" viewBox="0 0 32 32">
                      <title>Follow us on Twitter</title>
                      <g>
                        <path d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3 c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5 c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"></path>
                      </g>
                    </svg>
                  </a> 
                  <a class="coming-soon-v2__social-btn" href="https://www.instagram.com/flyingpigparade.nft/" target="_blank">
                    <svg class="icon" width="256px" height="256px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                      <path d="M160,128a32,32,0,1,1-32-32A32.03667,32.03667,0,0,1,160,128Zm68-44v88a56.06353,56.06353,0,0,1-56,56H84a56.06353,56.06353,0,0,1-56-56V84A56.06353,56.06353,0,0,1,84,28h88A56.06353,56.06353,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48.05436,48.05436,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z"/>
                    </svg>
                  </a>
                  <a class="coming-soon-v2__social-btn" href="https://medium.com/@FlyingPigParade" target="_blank">
                    <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.285 7.269a.733.733 0 0 0-.24-.619l-1.77-2.133v-.32h5.498l4.25 9.32 3.736-9.32H21v.319l-1.515 1.451a.45.45 0 0 0-.168.425v10.666a.448.448 0 0 0 .168.425l1.479 1.451v.319h-7.436v-.319l1.529-1.487c.152-.15.152-.195.152-.424V8.401L10.95 19.218h-.575L5.417 8.401v7.249c-.041.305.06.612.275.833L7.684 18.9v.319H2.036V18.9l1.992-2.417a.971.971 0 0 0 .257-.833V7.269z"/></svg>
                  </a>
                </li>
                <li><a class="reset cha-header__btn" href="#Oinklist" style="text-decoration: none;">Whitelist</a></li>
              </ul>
            </nav>

            <button class="reset cha-header__trigger js-cha-header__trigger js-tab-focus" aria-label="Open navigation" aria-expanded="false" aria-controls="cha-header-mobile">
              <svg class="icon" viewBox="0 0 24 24"><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" stroke="currentColor" fill="none" stroke-miterlimit="10"><line x1="1" y1="12" x2="23" y2="12"></line> <line stroke-miterlimit="10" x1="1" y1="5" x2="23" y2="5"></line> <line stroke-miterlimit="10" x1="1" y1="19" x2="23" y2="19"></line></g></svg>
            </button>
          </div>
        </div>
      </header>
    </div>
    <div class="padding-top-xl has-section-divider-bottom">
      <RevealSection></RevealSection>
    </div>
    <!-- <div class="section-divider">
      <svg viewBox="0 0 1920 60" aria-hidden="true"><path fill="var(--color-accent)" d="M53.906,29.5H0v47H1088.07c7.838.337,16.46.586,26.073.695l143.585,2.738c108.343,2.043,218.453,3.984,331.019,5.332q84.424,1.011,170.777,1.516l61.749.219H1920V19.251l-7.26.028-21.868.155-44.011.39c-83.637,1.477-178.947,2.024-256.264,1.965-118.5-1.312-208.571,1.691-320.754-1.152C1157.586,17.8,1100.489,9.152,945.855,9.045c-47.6.271-60.274,3.334-107.945,3.606S732.046,8.276,714.015,8.877c-95.287.547-171.869,4.418-263.641,7.484-36.017,1.2-83.691,1.473-89.991,3-12.77,3.061,10.48,4.92-55.129,5.793,23.27,1.86,82.54,2.516,76.221,4.045-1.6.1-3.258.2-4.95.3H215.969c-9.95-1.135-60.178-3.2-107.12-3.719l-53.993,1.8L19.917,24.8l46.554,3.717C64.814,28.917,60.3,29.232,53.906,29.5Zm1618.247-1.2c-.341.076-.853.148-1.345.217-2.723.371-7.737.656-13.486.861-1.292.046-2.613.089-3.937.127h-17.364l.111,0-.194,0H1512.29c-4.824-.191-9.133-.426-12.726-.713C1558.873,29.441,1606.49,29.17,1672.153,28.3Z"/></svg>
    </div> -->
  </section>
  <section v-for="(component, index) in componentCollection" class="position-relative padding-y-lg js-cha-section" :data-theme="theme(index)" :id="component['id']">
    <div class="container has-section-divider-bottom">
      <component :is="component['c']"></component>
    </div>
    <div class="section-divider">
      <svg viewBox="0 0 1920 60" aria-hidden="true"><path fill="var(--color-accent)" d="M53.906,29.5H0v47H1088.07c7.838.337,16.46.586,26.073.695l143.585,2.738c108.343,2.043,218.453,3.984,331.019,5.332q84.424,1.011,170.777,1.516l61.749.219H1920V19.251l-7.26.028-21.868.155-44.011.39c-83.637,1.477-178.947,2.024-256.264,1.965-118.5-1.312-208.571,1.691-320.754-1.152C1157.586,17.8,1100.489,9.152,945.855,9.045c-47.6.271-60.274,3.334-107.945,3.606S732.046,8.276,714.015,8.877c-95.287.547-171.869,4.418-263.641,7.484-36.017,1.2-83.691,1.473-89.991,3-12.77,3.061,10.48,4.92-55.129,5.793,23.27,1.86,82.54,2.516,76.221,4.045-1.6.1-3.258.2-4.95.3H215.969c-9.95-1.135-60.178-3.2-107.12-3.719l-53.993,1.8L19.917,24.8l46.554,3.717C64.814,28.917,60.3,29.232,53.906,29.5Zm1618.247-1.2c-.341.076-.853.148-1.345.217-2.723.371-7.737.656-13.486.861-1.292.046-2.613.089-3.937.127h-17.364l.111,0-.194,0H1512.29c-4.824-.191-9.133-.426-12.726-.713C1558.873,29.441,1606.49,29.17,1672.153,28.3Z"/></svg>
    </div>
  </section>

  <div class="cha-modal js-cha-modal z-index-header" id="cha-modal">
    <nav class="height-100% overflow-auto">
      <div class="bg padding-x-component padding-y-sm position-sticky z-index-1 top-0">
        <div class="flex justify-between items-center">

          <button class="reset cha-modal__close js-cha-modal__close js-tab-focus" aria-label="Close navigation">
            <svg class="icon" viewBox="0 0 24 24"><g stroke-linecap="square" stroke-linejoin="miter" fill="none" stroke-width="2" stroke="currentColor" stroke-miterlimit="10"><line x1="19" y1="5" x2="5" y2="19"></line><line x1="19" y1="19" x2="5" y2="5"></line></g></svg>
          </button>
        </div>
        </div>

      <div class="padding-x-component padding-y-lg">
        <ul class="flex flex-column gap-sm">
          <li><a href="#" class="cha-modal__link">Home</a></li>
          <li><a href="#Wilburs" class="cha-header__link">Wilbur's</a></li>
          <li><a href="https://fpp-whitepaper.s3.us-east-2.amazonaws.com/Whitepaper.pdf" target="_blank" class="cha-header__link">Whitepaper</a></li>
          <li><a href="#Lore" class="cha-header__link">Lore</a></li>
          <li>
            <a class="coming-soon-v2__social-btn" href="https://twitter.com/fppnftproject" target="_blank">
              <svg class="icon" viewBox="0 0 32 32">
                <title>Follow us on Twitter</title>
                <g>
                  <path d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3 c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5 c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"></path>
                </g>
              </svg>
            </a> 
            <a class="coming-soon-v2__social-btn" href="https://www.instagram.com/flyingpigparade.nft/" target="_blank">
              <svg class="icon" width="256px" height="256px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                <path d="M160,128a32,32,0,1,1-32-32A32.03667,32.03667,0,0,1,160,128Zm68-44v88a56.06353,56.06353,0,0,1-56,56H84a56.06353,56.06353,0,0,1-56-56V84A56.06353,56.06353,0,0,1,84,28h88A56.06353,56.06353,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48.05436,48.05436,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z"/>
              </svg>
            </a>
            <a class="coming-soon-v2__social-btn" href="https://medium.com/@FlyingPigParade" target="_blank">
              <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.285 7.269a.733.733 0 0 0-.24-.619l-1.77-2.133v-.32h5.498l4.25 9.32 3.736-9.32H21v.319l-1.515 1.451a.45.45 0 0 0-.168.425v10.666a.448.448 0 0 0 .168.425l1.479 1.451v.319h-7.436v-.319l1.529-1.487c.152-.15.152-.195.152-.424V8.401L10.95 19.218h-.575L5.417 8.401v7.249c-.041.305.06.612.275.833L7.684 18.9v.319H2.036V18.9l1.992-2.417a.971.971 0 0 0 .257-.833V7.269z"/></svg>
            </a>
          </li>
          <li><a href="#Oinklist" class="btn btn--primary btn--lg width-100%"  style="text-decoration: none;">Whitelist</a></li>
        </ul>
      </div>
    </nav>
  </div>