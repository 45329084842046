<template>
    <section class="position-relative z-index-1 padding-y-xl">
      <div class="container max-width-adaptive-lg">
        <div class="grid gap-lg items-center">
          

          <div class="col-7@md">
            <figure class="td-card js-td-card">
              <img class="block width-100% shadow-md radius-lg" src="../../assets/Wilburs.jpg" alt="Image description">
            </figure>
          </div>
          <div class="col-5@md">
            <div class="text-component">
              <h1>Welcome to Wilbur's</h1>
              <!-- <div class="text-left">
                The current landscape of crypto and NFTs feels like a wonderfully bizarre reality we are all populating. A literal world where pigs fly! Our project attempts to capture that feeling of childless wonder by bringing to fruition those flying pigs from your wildest imagination directly to the blockchain!
              </div> -->
              <div class="text-left margin-top-sm">
                Embodying the spirit of our main protagonist Wilbur and
                his namesake, Wilbur Wright, two pioneers of the skies,
                Wilbur’s stands poised to become the premier digital
                playground in the metaverse. Wilbur’s is intended to
                cultivate an experience unlike any other currently offered
                in the metaversal realm, with virtual events and
                experiences to satisfy the palette of everyone on board.
                Whether you're interested in socializing on the top deck
                while playing ping pong, dancing at a live concert in the
                music venue, or attending a hosted learning seminar in
                the Hogitorium, one thing is for certain: Wilbur's is the
                place to be! The metaversal skies await!
              </div>
            </div>
            <!-- <div class="margin-top-sm">
              <div class="text-left margin-left-lg">
                <a href="#0" class="color-inherit">Learn more</a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>
</template>


<script>
import { defineComponent } from 'vue'

export default defineComponent({
    mounted() { // 👈 load the JS code once the component is mounted
      let cardEffects = document.createElement('script');
      cardEffects.setAttribute('src', './js/components/_1_3d-card.js'); // 👈 make sure to use the correct path
      cardEffects.setAttribute('id', '_1_3d-card-js');

      document.body.appendChild(cardEffects);

    },
    unmounted() { // remove the JS code once the component has been destroyed
      document.getElementById('_1_3d-card-js').remove()
    }
})
</script>
<style lang="scss">
  
</style>
