import Pig1 from '@/assets/slide-pigs/Pig 1.webp'
import Pig2 from '@/assets/slide-pigs/Pig 2.webp'
import Pig3 from '@/assets/slide-pigs/Pig 3.webp'
import Pig4 from '@/assets/slide-pigs/Pig 4.webp'
import Pig5 from '@/assets/slide-pigs/Pig 5.webp'
import Pig6 from '@/assets/slide-pigs/Pig 6.webp'
import Pig7 from '@/assets/slide-pigs/Pig 7.webp'
import Pig8 from '@/assets/slide-pigs/Pig 8.webp'
import Pig9 from '@/assets/slide-pigs/Pig 9.webp'
import Pig10 from '@/assets/slide-pigs/Pig 10.webp'
import Pig11 from '@/assets/slide-pigs/Pig 11.webp'
import Pig12 from '@/assets/slide-pigs/Pig 12.webp'
import Pig13 from '@/assets/slide-pigs/Pig 13.webp'
import Pig14 from '@/assets/slide-pigs/Pig 14.webp'
import Pig15 from '@/assets/slide-pigs/Pig 15.webp'
import Pig16 from '@/assets/slide-pigs/Pig 16.webp'
import Pig17 from '@/assets/slide-pigs/Pig 17.webp'

const pigs = [
    Pig1,
    Pig2, 
    Pig3, 
    Pig4, 
    Pig5, 
    Pig6, 
    Pig7, 
    Pig8, 
    Pig9, 
    Pig10,
    Pig11,
    Pig12,
    Pig13,
    Pig14,
    Pig15,
    Pig16,
    Pig17 
]

export default pigs