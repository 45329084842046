<template>
    <section class="position-relative z-index-1 padding-y-xl">
      <div class="container max-width-adaptive-lg">
        <div class="margin-bottom-xl">
          <h1 class="text-center">Meet the Pilots</h1>
        </div>
    
        <div class="grid gap-xl">
          <div class="card-v13 col-3@lg col-6@md">
            <a class="card-v13__figure-wrapper" href="#0">
              <figure class="card-v13__figure">
                <img src="../../assets/pigs/gross.png" alt="Image description">
              </figure>
            </a>
          
            <div class="text-center margin-top-sm">
              <h3 class="text-md">
                <a class="color-contrast-higher" href="#0">Chief Dreamer</a>
              </h3>
    
              <div class="card-v13__separator margin-x-auto margin-y-sm" role="presentation"></div>

              <p class="color-contrast-medium text-sm line-height-lg">Meet our Chief Dreamer, he is constantly in search of new project direction options that will launch us to the oonmay. His child-like enthusiasm and endless energy are a crucial piece of the FPP puzzle. </p>
            </div>
          </div>
    
          <div class="card-v13 col-3@lg col-6@md">
            <a class="card-v13__figure-wrapper" href="#0">
              <figure class="card-v13__figure">
                <img src="../../assets/pigs/novelpig.png" alt="Image description">
              </figure>
            </a>
          
            <div class="text-center margin-top-sm">
              <h3 class="text-md">
                <a class="color-contrast-higher" href="#0">Chief Lyricist</a>
              </h3>
    
              <div class="card-v13__separator margin-x-auto margin-y-sm" role="presentation"></div>
    
              <p class="color-contrast-medium text-sm line-height-lg">Meet our Chief Lyricist. He artfully crafts the messages to the FPP community at large to capture our vision and inform the supporters of what exciting new places we are headed to next!</p>
            </div>
          </div>
    
          <div class="card-v13 col-3@lg col-6@md">
            <a class="card-v13__figure-wrapper" href="#0">
              <figure class="card-v13__figure">
                <img src="../../assets/pigs/StarVinceStraw.png" alt="Image description">
              </figure>
            </a>
          
            <div class="text-center margin-top-sm">
              <h3 class="text-md">
                <a class="color-contrast-higher" href="#0">Chief Seer</a>
              </h3>
    
              <div class="card-v13__separator margin-x-auto margin-y-sm" role="presentation"></div>
    
              <p class="color-contrast-medium text-sm line-height-lg">Meet our Chief Seer. He works closely with Chief Dreamer to help thoughtfully guide FPP toward on-brand and exciting new project missions.</p>
            </div>
          </div>
          <div class="card-v13 col-3@lg col-6@md">
            <a class="card-v13__figure-wrapper" href="#0">
              <figure class="card-v13__figure">
                <img src="../../assets/pigs/whatdidido.png" alt="Image description">
              </figure>
            </a>
          
            <div class="text-center margin-top-sm">
              <h3 class="text-md">
                <a class="color-contrast-higher" href="#0">Chief Mechanic</a>
              </h3>
    
              <div class="card-v13__separator margin-x-auto margin-y-sm" role="presentation"></div>
    
              <p class="color-contrast-medium text-sm line-height-lg">Meet our Chief Mechanic. He is an Engineer with 7+ years building and securing applications, his job is to give you the tools to do yours and keep your pigs fueled and flying to the OonMay for years to come.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        
    },
})
</script>
<style lang="scss">
 
</style>

