export default {
    
    "What are NFTs?": "NFT stands for “Non-Fungible Token” and it is a technology that enables you to easily prove ownership of a unique, digital asset. You can buy, trade, or simply own your NFT.",
    "How can I own an FPP JPIG?": "Owning a JPIG is easy! You will need to have any crypto wallet capable of storing $ETH and NFTs, send the required amount of $ETH to that wallet for minting and gas fees, click the mint button, and sign the transaction! The JPIG will automatically be transferred to your wallet for safe storage or can later be listed for sale on NFT Marketplaces like Opensea.",
    "What are gas fees?": "Gas fees are necessary costs to make any transaction on the Ethereum network; they help secure the Ethereum network and enable transactions to happen in a safe and immutable way.",
    "Why should I join the parade?": "Owning an FPP JPIG not only gives you a cute and rare piece of randomly generated art, but it also provides the unique opportunity to be joined with other NFT owners looking to fully embrace the foundational principles of a Web3 future. It will continue to have utility relating to our hosted experiences and surprises are sure to arise for your enjoyment! ",
    "Where will FPP trade in secondary?": "Hopefully everywhere NFTs are sold! We will post a list of verified marketplaces once the mint date is announced.",
    "How many JPIGS are there?": "Although there are a possible 600 Billion+ unique JPIGs possible, only 7887 will ever be brought to the blockchain! Grab yours now!",
    "How much is the mint price?": "Our Public Mint price will be .065 ETH. The Whitelist price for early supporters will only be .045 ETH!",
    // "Ah, I missed the mint! Where can I buy one?": "You can find our official secondary marketplaces here",
    "Where can I follow the project?": "The links are at the top, dummy! All aboard the Flying Pig Parade!",
    // "Okay, but where can I buy a JPIG?!?": "Man, we told you! Just go HERE!",
    "Oink?": "Oink!",
    "How many truffles can an FPP pig find in their lifetime?": "With advances in their ability to use tools (have you SEEN their vehicles?!) and an extremely lengthy lifespan, it’s not absurd to think an FPP JPIG’s truffle count might be more than the number of JPIGs available. We’re still counting, though, so we can’t be sure.",
}
    
