<template>
    <section class="position-relative z-index-1 padding-y-xl">
      <div class="container max-width-adaptive-lg">
        <div class="grid gap-lg items-center">
          

          <div class="col-7@md">
            <figure class="td-card js-td-card">
              <img class="block width-100% shadow-md radius-lg" src="../../assets/ParadeBanner.jpg" alt="Image description">
            </figure>
          </div>
          <div class="col-5@md">
            <div class="text-component">
              <h1>Why the Parade?</h1>
              <!-- <div class="text-left">
                The current landscape of crypto and NFTs feels like a wonderfully bizarre reality we are all populating. A literal world where pigs fly! Our project attempts to capture that feeling of childless wonder by bringing to fruition those flying pigs from your wildest imagination directly to the blockchain!
              </div> -->
              <div class="text-left margin-top-sm">
                We love what NFTs stand for and what they CAN be and wanted to find a unique way to contribute and advance the space. FPP’s focus is on constantly exploring new avenues and opportunities to engage the community while bettering the world in the process. We plan on staying nimble for our supporters in the pursuit of optimal advancement of FPP. 
              </div>
              <div class="text-left margin-top-sm">
                NFTs have not reached their full potential yet, and we aim to push the needle with your help just a little further toward the right direction. If that sounds like something you’re interested in, climb aboard the parade, we’d love to have you.
              </div>
              <div class="text-left margin-top-sm">
                Checkout <a href="#Wilburs" class="color-inherit">Wilbur's</a> for a preview of what's to come.
              </div>
            </div>
            <!-- <div class="margin-top-sm">
              <div class="text-left margin-left-lg">
                <a href="#0" class="color-inherit">Learn more</a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>
</template>


<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const pigImages = [
            'aviators',
            'laid pig',
            'novel pig',
        ]
        const getImageUrl = (pig) => {
            return ('./pigs/' + pig + '.png')
        }
        return {pigImages, getImageUrl}
    },
    mounted() { // 👈 load the JS code once the component is mounted
      let ValueProposition = document.createElement('script');
      ValueProposition.setAttribute('src', './js/components/_1_3d-card.js'); // 👈 make sure to use the correct path
      ValueProposition.setAttribute('id', '_1_3d-card-js');

      document.body.appendChild(ValueProposition);

    },
    unmounted() { // remove the JS code once the component has been destroyed
      document.getElementById('_1_3d-card-js').remove()
    }
})
</script>
<style lang="scss">
  
</style>
