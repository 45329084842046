<template>
    <div>
        <SimpleHeader>
            This is a test slot
        </SimpleHeader>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import SimpleHeader from '@/components/simple-header/SimpleHeader.vue'
export default defineComponent({
    setup() {
        
    },
    components:{
        SimpleHeader
    }
})
</script>
