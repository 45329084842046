<template>
    <div>
        <SimpleHeader>
            <div class="container max-width-lg padding-top-md">
                <h1 class="lore-header">In the Beginning...</h1>
                <div class="grid lore-container">
                    <img class="col-6@md col-12" src="@/assets/lore/the_calm_before_the_storm_new.jpg"/>
                    <div class="col-5@md col-12 description-container container margin-x-md margin-auto">
                        <h3>In the Beginning...</h3>
                        <div class="text-left padding-xs">
                            The story begins in the early ages; we’ll call it the First Epigch. 
                            Back then, pigs were just dopey farm animals that did nothing but roll in mud and eat slop. 
                            Most animals were that simplistic, too, just eat and play and sleep.
                        </div>
                        <div class="text-left padding-xs">
                            It was the wolves that first discovered that a world populated by such simple creatures was just begging to be overtaken. 
                        </div>
 

                    </div>
                </div>
                <div class="grid lore-container">
                    <img class="col-6@md col-12" src="@/assets/lore/wolf_wind.jpg"/>
                    <div class="col-5@md col-12 description-container container margin-auto">
                        <h3>Then the wolves came...</h3>
                        <div class="text-left  padding-xs">
                            While the wolves began to conquer, the pigs went about building protection from these fierce invaders. 
                            It was their houses of straw, stick, and a stone that gave them safety for a time, but, just like the pigs, the wolves soon adapted.
                        </div>
                    </div>
                </div>
                <div class="grid lore-container">
                    <img class="col-6@md col-12" src="@/assets/lore/wolf_burn_new.jpg"/>
                    <div class="col-5@md col-12 description-container container margin-auto">
                        <h3>The pigs tried to adapt...</h3>
                        to the wolves' advancements, but time and time again, the wolves broke down their defenses.
                    </div>
                </div>
                <div class="grid lore-container">
                    <img class="col-6@md col-12" src="@/assets/lore/wolf_castle.jpg"/>
                    <div class="col-5@md col-12 description-container container margin-auto">
                        <h3>At every turn...</h3>
                         and with every new solution, the pigs could not find lasting safety from the predators. It was clear that drastic measures would need to be taken to survive...
                    </div>
                </div>
                <div class="grid margin-bottom-md">
                    <img class="col-6@md col-12" src="@/assets/lore/poster_EPIGCH.png"/>
                    <div class="col-5@md col-12 container margin-auto margin-bottom">
                        <h3>Viva la Resistance...</h3>
                        <div>What will the pigs do next?</div>
                        <div>Join the Parade to find out!</div>
                        
                        <div>
                            <a class="coming-soon-v2__social-btn" href="https://twitter.com/fppnftproject" target="_blank">
                                <svg class="icon" viewBox="0 0 32 32">
                                <title>Follow us on Twitter</title>
                                <g>
                                    <path d="M32,6.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6C25.7,3.8,24,3,22.2,3 c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5C10.3,10.8,5.5,8.2,2.2,4.2c-0.6,1-0.9,2.1-0.9,3.3c0,2.3,1.2,4.3,2.9,5.5 c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1C2.9,27.9,6.4,29,10.1,29c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C30,8.5,31.1,7.4,32,6.1z"></path>
                                </g>
                                </svg>
                            </a> 
                            <a class="coming-soon-v2__social-btn" href="https://www.instagram.com/flyingpigparade.nft/" target="_blank">
                                <svg class="icon" width="256px" height="256px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                                <path d="M160,128a32,32,0,1,1-32-32A32.03667,32.03667,0,0,1,160,128Zm68-44v88a56.06353,56.06353,0,0,1-56,56H84a56.06353,56.06353,0,0,1-56-56V84A56.06353,56.06353,0,0,1,84,28h88A56.06353,56.06353,0,0,1,228,84Zm-52,44a48,48,0,1,0-48,48A48.05436,48.05436,0,0,0,176,128Zm16-52a12,12,0,1,0-12,12A12,12,0,0,0,192,76Z"/>
                                </svg>
                            </a>
                            <a class="coming-soon-v2__social-btn" href="https://medium.com/@FlyingPigParade" target="_blank">
                                <svg class="icon" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4.285 7.269a.733.733 0 0 0-.24-.619l-1.77-2.133v-.32h5.498l4.25 9.32 3.736-9.32H21v.319l-1.515 1.451a.45.45 0 0 0-.168.425v10.666a.448.448 0 0 0 .168.425l1.479 1.451v.319h-7.436v-.319l1.529-1.487c.152-.15.152-.195.152-.424V8.401L10.95 19.218h-.575L5.417 8.401v7.249c-.041.305.06.612.275.833L7.684 18.9v.319H2.036V18.9l1.992-2.417a.971.971 0 0 0 .257-.833V7.269z"/></svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
        </SimpleHeader>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import SimpleHeader from '@/components/simple-header/SimpleHeader.vue'
export default defineComponent({
    setup() {
        
    },
    components:{
        SimpleHeader
    }
})
</script>
<style scoped lang="scss">
    @import "@/css/base/breakpoints.scss";

    .description-container {
        display: none;
    }
    
    .lore-container {
        margin-bottom: 0;
    }

    

    @include breakpoint(md) {
        .description-container {
            display: block;
        }
        .lore-container {
            margin-bottom: var(--space-sm);
        }
        .lore-header {
            display: none;
        }
    }
    
</style>